// @ts-ignore
const sentryConfig = window._APP_.sentry;

const options = {
  allowUrls: sentryConfig.allowUrls,
  attachStacktrace: true,
  dsn: sentryConfig.dsn,
  environment: sentryConfig.env,
  ignoreErrors: sentryConfig.ignoreErrors,
  release: sentryConfig.release,
  tracesSampleRate: sentryConfig.tracesSampleRate,
  tunnel: sentryConfig.tunnel
};
export default options;
